import { render, staticRenderFns } from "./userResult.vue?vue&type=template&id=7ff8b27a&scoped=true"
import script from "./userResult.vue?vue&type=script&lang=js"
export * from "./userResult.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/user/userResult.css?vue&type=style&index=0&id=7ff8b27a&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff8b27a",
  null
  
)

export default component.exports